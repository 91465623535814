<template>
    <slot></slot>
</template>

<script>
import { gsap, SplitText } from "gsap/all";
import { mapActions, mapState } from "vuex";
gsap.registerPlugin(SplitText);

export default {
    name: "TextReveal",
    data() {
        return {};
    },
    computed: {
        ...mapState("main", ["isFontsLoaded"]),
        ...mapState("metrics", ["width"]),
    },
    watch: {
        isFontsLoaded: "onFontsLoaded",
        width: "onResize",
    },
    mounted() {
        this.$selectedElement = this.$el.nextElementSibling
        // Split lines
        this.splitText();
    },
    methods: {
        ...mapActions("main", ["forceScrollUpdate"]),

        // Events
        onFontsLoaded() {
            this.splitText();
        },

        onResize() {
            this.splitText();
        },

        splitText() {
            if (this.split) {
                this.split.revert();
                this.split = null;
            }

            this.split = new SplitText(this.$selectedElement, {
                type: this.splitType,
                linesClass: `u-anim-text_line`,
                wordsClass: `u-anim-text_word`
            });

            const totalLines = this.split.lines.length;
            this.$selectedElement.style.setProperty(
                "--lines-total",
                totalLines
            );

            this.split.lines.forEach(($line, i) => {
                $line.style.setProperty("--line-index", i);
            });

            this.$nextTick(() => {
                this.forceScrollUpdate;
            });
        },
    },
};
</script>